import React, { useState, useEffect } from "react";
import Typist from 'react-typist-component';

export default function BotMessage({ fetchMessage, isWelcome }) {
  const [isLoading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    async function loadMessage() {
      const msg = await fetchMessage();
      setLoading(false);
      setMessage(msg);
    }
    loadMessage();
  }, [fetchMessage]);

  // Function to safely render HTML content or plain text
  const renderMessageContent = () => {
    if (isLoading) {
      return (<div className="bot-message">...</div>);
    } else if (isWelcome) {
      const theMessage = `<Typist restartKey={message} typingDelay={20}>${message}</Typist>`;
      // Use dangerouslySetInnerHTML to render HTML
      return (

           <div className="bot-message">
              I'm Vincent Chu AI. I'm trained on Vincent's experience and writings. 
              Feel free to ask me about Vincent's experience, and I will try my best to answer you. 
              If you like what you see, please message <a href='https://www.linkedin.com/in/vincentchu/' target='_blank'>
                https://www.linkedin.com/in/vincentchu/
                </a>. 
            </div>

      );
    } else {
      // Render as plain text
      return (
        <div className="bot-message">{message}</div>
      );
    }
  };

  return (
    <div className="message-container">
      <Typist restartKey={message} typingDelay={20}>
          {renderMessageContent()}
      </Typist>
    </div>
  );
}
